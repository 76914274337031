import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../../layout'
import SEO from '../../../utils/seo'

import BannerDivider from '../../bannerDivider'
import { ContainerPageAbout, WrapperPagesAbout } from './styles'

const AboutLayout = (props) => {
    return (
        <Layout>
            <SEO title="Empresa" />
            <BannerDivider title="Sobre a Empresa" subtitle="Crescimento constante" />
            <ContainerPageAbout className="col-10 mt-3px">
                <WrapperPagesAbout>
                    <Link to="/sobre/quem-somos">
                        <div
                            role="button"
                            tabIndex="0"
                            className={`buttonPageAbout effectPageAbout ${
                                props.type === 'how-are' ? 'activePageAbout' : null
                            }`}
                        >
                            <h3 className="font-subtitle">Quem somos?</h3>
                        </div>
                    </Link>

                    <Link to="/sobre/nosso-time">
                        <div
                            role="button"
                            tabIndex="0"
                            className={`buttonPageAbout effectPageAbout ${
                                props.type === 'our-team' ? 'activePageAbout' : null
                            }`}
                        >
                            <h3 className="font-subtitle">Nosso time</h3>
                        </div>
                    </Link>

                    <Link to="/sobre/trabalhe-conosco">
                        <div
                            role="button"
                            tabIndex="0"
                            className={`buttonPageAbout effectPageAbout ${
                                props.type === 'work-us' ? 'activePageAbout' : null
                            }`}
                        >
                            <h3 className="font-subtitle">Trabalhe conosco</h3>
                        </div>
                    </Link>
                </WrapperPagesAbout>

                <section className="about-content">{props.children}</section>
            </ContainerPageAbout>
        </Layout>
    )
}

export default AboutLayout
