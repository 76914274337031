import React, { useEffect, useState } from 'react'
import { useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import AboutLayout from '../../components/Layout/About'

import ContainerWorkUs from '../../styles/workUs'

import { AlertBanner } from '../../components/alerts'
import { CardAvailableOffice } from '../../components/cards'

import Loader from '../../components/loading'
import { API } from '../../utils/api'

const WorkUsPage = () => {
    const { workUs } = useStaticQuery(graphql`
        query {
            workUs: allStrapiSobre {
                nodes {
                    id
                    work_with_us
                    work_with_us_image {
                        publicURL
                    }
                }
            }
        }
    `)

    const [isLoading, setIsLoading] = useState(true),
        [availables, SetAvailables] = useState([])

    useEffect(() => {
        API.get('/oportunidades')
            .then((resp) => {
                SetAvailables(resp.data)
                setIsLoading(false)
            })
            .catch((e) => {
                console.log('error', e)
                setIsLoading(false)
            })
    }, [])

    return (
        <AboutLayout type="work-us">
            <ContainerWorkUs backgroundImage={workUs?.nodes[0]?.work_with_us_image?.publicURL}>
                <div className="backgroundContentAbout">
                    <h1 className="font-subtitle">Trabalhe conosco</h1>
                </div>

                <span className="bannerWorkUs" />

                <ReactMarkdown className="text-about">
                    {workUs?.nodes[0]?.work_with_us}
                </ReactMarkdown>

                <div className="cardAvailableOffice mt-4 mb-2">
                    <div className="backgroundContentAbout font-center">
                        <h2 className="font-subtitle ">Vagas disponíveis</h2>
                    </div>

                    {isLoading ? (
                        <Loader message={'Buscando oportunidades...'} />
                    ) : availables.length === 0 ? (
                        <AlertBanner
                            message={'Lamentamos, no momento não temos nenhuma vaga disponível...'}
                        />
                    ) : (
                        availables?.map((i) => (
                            <CardAvailableOffice
                                key={i.strapiId}
                                title={i.titleOffice}
                                description={i.description}
                                linkButton={i.link_Linkedin}
                            />
                        ))
                    )}
                </div>
            </ContainerWorkUs>{' '}
        </AboutLayout>
    )
}

export default WorkUsPage
