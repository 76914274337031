import styled from 'styled-components'

export default styled.section`
    .bannerWorkUs {
        height: 350px;
        width: 100%;
        background-image: ${(props) => `url(${props.backgroundImage})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;

        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-end;
    }

    .cardAvailableOffice {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`
