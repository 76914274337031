import styled from 'styled-components'

export const AlertBannerStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .unavailableOfficeAlert {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 30px 0;
        width: 80%;

        background-color: #393737;
        padding: 20px;
        border-radius: 10px;

        .unavailableIconOffice {
            font-size: 50px;
            flex-basis: 10%;
        }

        .contentUnavailableOffice {
            flex-basis: 85%;
            align-self: center;
        }
    }
`
