import styled from 'styled-components'

export const ContainerPageAbout = styled.div`
    display: flex;
    justify-content: space-around;

    .about-content {
        flex-basis: 70%;
    }

    .text-about {
        p {
            line-height: 35px;
            text-align: justify;

            margin-top: 24px;
        }
    }

    .backgroundContentAbout {
        padding: 5px 20px;
        background: #393737;
        color: #f0ebeb;
        width: 55%;

        margin-bottom: 20px;
    }

    @media only screen and (max-width: 798px) {
        flex-direction: column;

        .backgroundContentAbout {
            width: 100%;
        }
    }
`

export const WrapperPagesAbout = styled.div`
    flex-basis: 25%;

    a {
        text-decoration: none;
        color: inherit;
    }

    .buttonPageAbout {
        position: relative;
        background-color: #c4c4c4;
        width: 25vw;
        padding: 15px 10px;
        margin-bottom: 20px;
        text-align: left;

        border-left: 5px solid #d32327;

        transition: color 0.5s ease;
        cursor: pointer;
        user-select: none;

        &:hover {
            color: #f0ebeb;
            a {
                color: #f0ebeb;
            }
        }
    }

    .downloadPageAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .downloadIconAbout {
            font-size: 20px;
            margin-right: 10px;
        }
    }

    .linkDownloadAbout {
        color: #393737;
        text-decoration: none;
    }

    .activePageAbout {
        background-color: #d32327;
        color: #f0ebeb;
    }

    .effectPageAbout {
        transform: translateZ(0);
        transition-property: color;
        transition-duration: 0.4s;

        :before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #d32327;
            transform: scaleX(0);
            transform-origin: 0% 100%;
            transition-property: transform;
            transition-duration: 0.4s;
            transition-timing-function: ease-out;
        }
        :hover,
        :focus,
        :active {
            color: #f0ebeb;
        }
        :hover:before,
        :focus:before,
        :active:before {
            transform: scaleX(1);
        }
    }

    @media only screen and (max-width: 798px) {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        h3 {
            font-size: 3.5vw !important;
        }

        .downloadPageAbout {
            width: 85vw;
        }
    }
`
